.navbar {
  display: flex;
  margin: 0 0 0 15px;
}

.nav-element {
  color: #848c93;
  padding: 5px 10px 5px 10px;
}

.nav-element:hover {
  color: #e1e4e8;
  background-color: #363a3e;
  cursor: pointer;
}

.icon {
  margin-top: 3px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
