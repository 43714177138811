body {
  background-color: #24292e;
}

.code-text {
  color: white;
  font-family: Fira Mono, Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: break-word;
  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  counter-reset: a;
}

.code-line {
  counter-increment: a;
  display: inline-table;
  padding-left: 3ch;
  position: relative;
}

.code-line::before {
  color: grey;
  content: counter(a) "\a●\a●\a●\a●\a●\a●\a●\a●\a●\a●";
  display: inline-block;
  opacity: 0.5;
  position: absolute;
  width: 3ch;
  top: 0;
  bottom: 0;
  left: -1ch;
  overflow: hidden;
  text-align: right;
}

.code-red {
  color: #eb4e3e;
}

.code-purple {
  color: #b392f0;
}

.code-blue {
  color: #4dabff;
}

.code-value {
  color: #9ecbff;
}

.code-orange {
  color: #ff9f4e;
}

.code-comment {
  color: #6a737d;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
